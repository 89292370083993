import { useCookie } from '#imports';

export const useSocialRedirectUrl = () => {
  const socialRedirectUrl = useCookie('ex-social-redirect-url', {
    default: () => '/',
  });

  const updateSocialRedirectUrl = () => {
    socialRedirectUrl.value = globalThis.location.href;
  };

  return { socialRedirectUrl, updateSocialRedirectUrl };
};
